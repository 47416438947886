import gql from "graphql-tag";
import candidateFragment from "./CandidateFragment";
import userFragment from "./UserFragment";

export const GET_CANDIDATE_BY_ID_QUERY = gql`
  query GetCandidateById($id: ID!) {
    candidate: getCandidateById(id: $id) {
      ...${candidateFragment}
    }
  }
`;

export const USER_QUERY = gql`
  query User {
    user: user {
      ...${userFragment}
    }
  }
`;

export const SEND_STATUS = gql`
  mutation sendStatusCandidate(
    $user_id: ID
    $description: String
    $locale: String
    $candidate: String
  ) {
    sendStatusCandidate(
      user_id: $user_id
      description: $description
      locale: $locale
      candidate: $candidate
    ) {
      description
      locale
    }
  }
`;

export const GET_TRAINING_AREAS = gql`
  query getTrainngAreas {
    getTrainingAreas {
      id
      text {
        en
        pt
      }
      key
    }
  }
`;
